.App {
  text-align: center;
  color: white;
}

.App-header {
  height: calc(100vh - 350px);
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 6vmin);
}

.About-header {
  background-color: #e67e22;
  height: 100vh;
  padding: 100px 100px;
  display: flex;
  flex-direction: column;
  font-size: calc(10px + 3vmin);
}

.about-points li {
  font-size: 0.6em;
  padding: 0.2em 0;
}

.header-padding {
  padding: 1em;
}

.App-link {
  color: #61dafb;
}

a {
  color: white;
}

.about {
  max-width: 1200px;
  margin: 0 auto;
  margin-top: 50px;
}

.text-left {
  text-align: left;
}

.float-right {
  float: right;
}

.logo {
  max-width: 200px;
}

.slogan {
  font-size: 18px;
  color: #e9ecef;
}

/* Main Navigation */

.menu-trigger {
  cursor: pointer;
  z-index: 101;
  position: relative;
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  transition: 350ms;
  float: right;
}

.menu-trigger .line {
  height: 1.6px;
  width: 32px;
  margin-bottom: 7px;
  background-color: #fff;
  display: block;
  position: relative;
  /* -webkit-transition: all 0.15s cubic-bezier(0.63, 0.62, 0.48, 1.64) 0.1s;
     -moz-transition: all 0.15s cubic-bezier(0.63, 0.62, 0.48, 1.64) 0.1s;
     transition: all 0.15s cubic-bezier(0.63, 0.62, 0.48, 1.64) 0.1s;
     -moz-transform: rotateZ(0deg) translateX(0px) translateY(0px);
     -o-transform: rotateZ(0deg) translateX(0px) translateY(0px);
     -ms-transform: rotateZ(0deg) translateX(0px) translateY(0px);
     -webkit-transform: rotateZ(0deg) translateX(0px) translateY(0px);
     transform: rotateZ(0deg) translateX(0px) translateY(0px) */
}

.menu-trigger .line:last-child {
  margin-bottom: 0;
}

.menu-trigger .line.second {
  -moz-transform: none;
  -o-transform: none;
  -ms-transform: none;
  -webkit-transform: none;
  transform: none;
}

.menu-trigger.active .line.second,
.menu-trigger.fixed-active .line.second {
  opacity: 0;
}

.menu-trigger.active .line.first,
.menu-trigger.fixed-active .line.first {
  -moz-transform: rotateZ(48deg) translateX(6px) translateY(6px) !important;
  -o-transform: rotateZ(48deg) translateX(6px) translateY(6px) !important;
  -ms-transform: rotateZ(48deg) translateX(6px) translateY(6px) !important;
  -webkit-transform: rotateZ(48deg) translateX(6px) translateY(6px) !important;
  transform: rotateZ(48deg) translateX(6px) translateY(6px) !important;
}

.menu-trigger.active .line.third,
.menu-trigger.fixed-active .line.third {
  -moz-transform: rotateZ(-46deg) translateX(6px) translateY(-6px) !important;
  -o-transform: rotateZ(-46deg) translateX(6px) translateY(-6px) !important;
  -ms-transform: rotateZ(-46deg) translateX(6px) translateY(-6px) !important;
  -webkit-transform: rotateZ(-46deg) translateX(6px) translateY(-6px) !important;
  transform: rotateZ(-46deg) translateX(6px) translateY(-6px) !important;
}

#main-navigation ul {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  float: right;
}

#main-navigation ul li {
  margin-right: 10px;
  opacity: 0;
  transition: 350ms;
}

#main-navigation ul li.twitter-icon {
  transition-delay: 400ms;
}

#main-navigation ul li.facebook-icon {
  transition-delay: 300ms;
}

#main-navigation ul li.instagram-icon {
  transition-delay: 200ms;
}

#main-navigation.active ul li {
  opacity: 1;
}

#main-navigation ul li a {
  -webkit-transition: 350ms;
  -moz-transition: 350ms;
  transition: 350ms;
  color: white;
  text-decoration: none;
}

#main-navigation ul li a:hover {
  transform: translate(0, -5px);
}

/*
Clearfix
*/

.clearfix:after {
  content: "";
  display: table;
  clear: both;
}

.header-right {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.header-right {
  padding: 30px 30px 0 30px;
  justify-content: flex-end;
}

@media (min-width: 320px) and (max-width: 480px) {
  .About-header {
    font-size: inherit;
    padding: 100px 0;
  }

  .about {
    max-width: 80%;
  }

  .menu-trigger,
  #main-navigation {
    font-size: 12px;
  }
}
