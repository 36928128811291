html {
  overflow: hidden;
}

body {
  background-color: #1d1d1d;
  /* background-image: url(/bg.jpg); */
  background-size: cover;
  height: 100vh;
  margin: 0;
  padding: 0px;
  border: none;
  overflow:hidden;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: 'gilroyextrabold';
  margin: 0;
}


@font-face {
    font-family: 'gilroyextrabold';
    src: url('./fonts/gilroy-extrabold-webfont.woff2') format('woff2'),
         url('./fonts/gilroy-extrabold-webfont.woff') format('woff');
    font-weight: normal;
    font-style: normal;

}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

.align-center {
  display:flex;
  justify-content: center;
}
