.social-links {
  display: flex;
  justify-content: space-around;
  align-items: center;
  max-width: 80%;
  font-size: 16px;
  opacity: .4;
  margin: auto;
  flex-direction: row;
  flex-wrap: wrap;
}

ul li {
  padding: 8px;
}

ul li img {
  width: 100px;
}

#first-list-item { 
  padding-left: 0;
}