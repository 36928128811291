li {
  list-style-type: none;
  /* float: left; */
}

ul {
  padding: 0px;
}

.float-right {
  float: right;
}

.navigation-bar {
  padding: 0 30px;
}
